(function ($) {
  $("#cookie-compliance").each(function () {
    var that = $(this);
    var gdpr = Cookies.get('cookie_compliance');

    if (gdpr == undefined) {
      that.show();
    }

    that.find('.cookie-compliance__button a').on("click", function (e) {
      e.preventDefault();
      that.hide();
      Cookies.set('cookie_compliance', 'yes');
    });
  });
})(jQuery);
