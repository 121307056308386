(function ($) {
  $('.presentation').each(function () {
    var navTab = $(this).find(".nav-tab");
    var carouselCat01Html = $("#tab-cat01 .slider").html();
    var carouselCat02Html = $("#tab-cat02 .slider").html();
    var carouselCat03Html = $("#tab-cat03 .slider").html();
    var carouselCat04Html = $("#tab-cat04 .slider").html();
    var carouselCat05Html = $("#tab-cat05 .slider").html();
    var settings = {
      init: false,
      observer: true,
      observeParents: true,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      //   clickable: true,
      // },
    };

    var carouselInit = $("#tab-cat01").find(".swiper-container");
    var mySwiperInit = new Swiper(carouselInit, settings);
    mySwiperInit.init();

    navTab.on("click", function (e) {
      e.preventDefault();

      var link = $(this).attr("class").split(' ')[1];
      var target = $(this).attr("href");

      navTab.removeClass('active');
      $(this).addClass('active');

      $(".tab").removeClass('open');
      $(target).addClass('open');

      // Carousel
      var carousel = $(target).find(".swiper-container");
      var mySwiper = new Swiper(carousel, settings);

      if (!carousel.hasClass('swiper-container-initialized')) {
        mySwiper.init();
      } else {
        if (link == "cat01") {
          $(target).find(".projets .inner").html(carouselCat01Html);
        } else if (link == "cat02") {
          $(target).find(".projets .inner").html(carouselCat02Html);
        } else if (link == "cat03") {
          $(target).find(".projets .inner").html(carouselCat03Html);
        } else if (link == "cat04") {
          $(target).find(".projets .inner").html(carouselCat04Html);
        } else if (link == "cat05") {
          $(target).find(".projets .inner").html(carouselCat05Html);
        }

        var carouselReload = $(target).find(".swiper-container");
        var mySwiperReload = new Swiper(carouselReload, settings);
        mySwiperReload.init();
      }
    })
  });
})(jQuery);
