(function ($) {
  $('#nbrbrunch').hide();

  $('input[name="inscription[brunch]"]').bind('change', function () {
    if ($(this).val() == 1) {
      $('#nbrbrunch').show();
    }
    if ($(this).val() == 0) {
      $('#nbrbrunch').hide();
    }
  })
})(jQuery);
