(function ($) {
  $('.main-header').each(function () {
    $(window).scroll(function (e) {
      if ($(window).scrollTop() > 60) {
        $('.main-header').addClass('scrolled');
      } else {
        $('.main-header').removeClass('scrolled');
      }
    });

    $(this).find(".ctas .cta.devis").on("click", function (e) {
      e.preventDefault();

      var target = $(this.hash);
      $('html, body').animate({
        scrollTop: target.offset().top - 60
      }, 1000);
    });
  });
})(jQuery);
